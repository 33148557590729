const dobbiesProd =  {
  search: {
      inputBox: ".ms-search__form.bx-show input.ms-search__form-control",
      queryBtn: ".ms-search__form.bx-show .ms-search__form-submitSearch.msc-btn",
      urlParam: "q"
  },
  productClickGrid: {
      productElemWrapper: ".ms-search-result-container__Products .ms-product-search-result__item",
      pidSelector:".ms-search-result-container__Products .ms-product-search-result__item .msc-product",
      productPidAttr: "data-product-id",
      source: "PRODUCT_CLICK_GRID"
  },
  addToCartFromPDP: {
      addToCartBtnSelector: ".buttons-container .msc-add-to-cart",
      pidSelector: ".buttons-container .msc-add-to-cart",
      productPidAttr: "data-product-id",
      qtySelector: ".add-to-cart-container #ms-buybox__product-quantity-input input.quantity-input",
      source: "ADD_TO_CART_PDP",
  },
  // addToCartFromCartPage will be handle from the customer because they have customer dropdown.
  addToCartFromCartPage: {
      cartItemWrapper: "#dobbies-cart .msc-cartline-wraper .msc-cart-lines-item",
      productPidAttr: "data-product-id",
      qtySelector: "#dobbies-cart .msc-cartline-wraper .msc-cart-lines-item .quantity input.quantity-input",
      qtyPlusSelector: ".msc-cart-line__product-quantity .quantity button.increment.quantity__controls ",
      qtyMinusSelector: ".msc-cart-line__product-quantity .quantity button.decrement.quantity__controls ",
      source: "ADD_TO_CART_FROM_CART_PAGE"
  },
  addToCartFromCartPopup: {
      cartItemWrapper: "#basket-quickitems .basketListItem",
      pidSelector: "#basket-quickitems .basketListItem .itemQuan input",
      productPidAttr: "class",
      getPidFromCB: function (pidString) {
          let pid = 'p-' + pidString.split(':')[0];
          return pid;
      },
      qtySelector: "#basket-quickitems .basketListItem .itemQuan input",
      qtyPlusSelector: '#basket-quickitems .basketListItem .itemQuan .increase_cart_quantity',
      qtyMinusSelector: '#basket-quickitems .basketListItem .itemQuan .decrease_cart_quantity',
      qtyDelay: 2500,
      source: "ADD_TO_CART_FROM_CART_POPUP",
  },
  pageView: {
      events: {
          home: {
            debounceDelay: 6000,
              urlIdentifiers: {
                  urls: ["https://www.dobbies.com/"],
                  exactMatch: true
              }
          },
          search: {
              uniqueSelectors: {
                  selectors: ['#renderPage[data-exp-event-id="Search.PageView"]']
              },
              searchRecall: {
                noOfResultsSelector: '.ms-search-result-container__title.result-count-search .ms-search-result__collection-title-count',
                getSRCountCB: function (str) {
                    const match = str.replace(/[^\d-]/g, '');
                    return match;
                }
              }
          },
          productDisplay: {
              uniqueSelectors: {
                  selectors: ['#renderPage[data-exp-event-id="DefaultPdp.PageView"]']
              }
          },
          cart: {
              uniqueSelectors: {
                  selectors: ["#dobbies-cart"]
              },
              urlIdentifiers: {
                  urls: ["/cart"],
                  exactMatch: false
              }
          },
          // order: {
          //     uniqueSelectors: {
          //         selectors: ["#checkoutsuccessBody"]
          //     }
          // }
      }
  }
};

export default dobbiesProd;
